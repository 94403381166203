<template>
  <div>
    <b-card title="Filtro">
      <b-row>
        <b-col>
          <label>Mês</label>
          <b-form-select
            :disabled="!rerender"
            v-model="selectedMonth"
            :options="monthOptions"
          ></b-form-select>
        </b-col>
        <b-col>
          <label>Ano</label>
          <b-form-select
            :disabled="!rerender"
            v-model="selectedYear"
            :options="yearOptions"
          ></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Base de cálculo">
      <b-row>
        <b-col>
          <h5 class="text-center mb-2">
            {{
              `Depreciação de Bens - R$ ${numberToMonetary(
                response.totalGoodsDepreciation
              )}`
            }}
          </h5>
        </b-col>
        <b-col>
          <h5 class="text-center">
            {{
              `Colaboradores - R$ ${numberToMonetary(response.totalUsersCosts)}`
            }}
          </h5>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="mt-2">
      <b-col md="6">
        <b-card class="h-100" sub-title="Bens">
          <div v-if="goodsFiltered.length == 0">
            <b-alert show variant="primary">
              <p class="text-center">
                <br />
                Não existe <strong>Bens depreciáveis</strong> dentro do escopo
                deste escritório na data escolhida.
              </p>
              <br />
            </b-alert>
          </div>

          <b-row
            class="m-2"
            v-for="(good, index) in goodsFiltered"
            :key="index"
          >
            <b-col class="mt-1">
              <b-badge variant="primary">{{ good.item_name }}</b-badge> <b-badge v-if="alredyCalculated" variant="success">Salvo</b-badge
              ><br />
              <span style="font-size: 10px"
                >Vida útil: {{ good.remainingLifespan }}meses</span
              >
            </b-col>
            <b-col>
              <label for="">Valor</label>
              <b-form-input
                disabled
                v-money="moneyMask"
                v-model="good.depreciation_value"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card class="h-100" sub-title="Colaboradores">
          <b-row
            class="m-1"
            v-for="(employee, index) in response.usersWithCosts"
            :key="index"
          >
            <b-col cols="12">
              <b-badge variant="primary" class="">{{
                employee.username
              }}</b-badge
              > <b-badge v-if="alredyCalculated" variant="success">Salvo</b-badge
              > <br />
            </b-col>
            <b-col cols="12">
              <b-form-input
                v-money="moneyMask"
                v-model="employee.value"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-right">
              <b-button variant="success" @click="calcCosts">Calcular</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BBadge,
  BButton,
  BAlert,
} from 'bootstrap-vue';
import moment from 'moment';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BBadge,
    BButton,
    BAlert,
  },
  data: () => ({
    item: {},
    response: { totalUsersCosts: 0, totalGoods: 0 },
    monthExpenses: 0,
    yearOptions: [],
    alredyCalculated: false,
    monthOptions: [
      { value: 1, text: 'Janeiro' },
      { value: 2, text: 'Fevereiro' },
      { value: 3, text: 'Março' },
      { value: 4, text: 'Abril' },
      { value: 5, text: 'Maio' },
      { value: 6, text: 'Junho' },
      { value: 7, text: 'Julho' },
      { value: 8, text: 'Agosto' },
      { value: 9, text: 'Setembro' },
      { value: 10, text: 'Outubro' },
      { value: 11, text: 'Novembro' },
      { value: 12, text: 'Dezembro' },
    ],
    selectedMonth: moment().month(),
    selectedYear: moment().year(),
    rerender: true,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
    elapsedMonths: 0,
    goodsFiltered: [],
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },
  async created() {
    this.yearOptions = this.generateYearOptions();
    this.monthOptions = this.generateMonthOptions();
    await this.getAlredyCalculated();
    await this.getOfficeExpenses();
    this.getCalcCosts();
  },
  watch: {
    selectedMonth: ['getCalcCosts', 'getOfficeExpenses', 'getAlredyCalculated'],
    selectedYear: ['getCalcCosts', 'getOfficeExpenses', 'updateMonthOptions', 'getAlredyCalculated'],
  },
  methods: {
    generateYearOptions() {
      return Array.from({ length: 11 }, (_, i) => {
        const year = this.currentYear - i;
        return { value: year, text: year.toString() };
      });
    },
    generateMonthOptions() {
      const isCurrentYear = this.selectedYear === this.currentYear;
      const maxMonth = isCurrentYear ? this.selectedMonth : 12;

      console.log('maxMonth', maxMonth);

      return Array.from({ length: maxMonth }, (_, i) => ({
        value: i + 1,
        text: moment().month(i).format('MMMM'),
      }));
    },
    updateMonthOptions() {
      this.monthOptions = this.generateMonthOptions(this.selectedYear);

      if (
        this.selectedYear === this.currentYear &&
        this.selectedMonth >= this.currentMonth
      ) {
        this.selectedMonth = this.currentMonth - 1;
      }
    },
    async getOfficeExpenses() {
      const startDate = this.selectedMonth
        ? moment(`${this.selectedYear}-${this.selectedMonth}-01`)
            .startOf('month')
            .format('YYYY-MM-DD')
        : null;

      const endDate = this.selectedMonth
        ? moment(`${this.selectedYear}-${this.selectedMonth}-01`)
            .endOf('month')
            .format('YYYY-MM-DD')
        : null;

      this.items = [];
      this.$store
        .dispatch('getAllProjectCosts', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          start_date: startDate,
          end_date: endDate,
          is_project_cost: false,
        })
        .then((resp) => {
          this.monthExpenses =
            resp && resp.totals && resp.totals.totalGeral
              ? resp.totals.totalGeral
              : 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAlredyCalculated() {
      this.$store
        .dispatch('getAllCalculations', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          month: this.selectedMonth,
          year: this.selectedYear,
        })
        .then((resp) => {
          if(resp && resp.data && resp.data.length > 0){
            this.alredyCalculated = true;
          } else{
            this.alredyCalculated = false;
          }
        });
    },

    async getCalcCosts() {
      this.rerender = false;
      if (!this.userList) {
        this.$store
          .dispatch('getCalcCosts', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            month: this.selectedMonth,
            year: this.selectedYear,
          })
          .then((resp) => {
            this.rerender = true;

            this.response.goods = resp.goods.map((good) => {
              const acquisitionDate = new Date(good.acquisition_date);
              const lifespanMonths = good.lifespan_months;
              const currentDate = new Date(
                this.selectedYear,
                this.selectedMonth - 1
              );

              const monthsElapsed =
                (currentDate.getFullYear() - acquisitionDate.getFullYear()) *
                  12 +
                (currentDate.getMonth() - acquisitionDate.getMonth());

              this.elapsedMonths = monthsElapsed;

              good.remainingLifespan = lifespanMonths - monthsElapsed;
              return good;
            });

            this.goodsFiltered = this.response.goods
              .filter((item) => item.lifespan_months >= item.remainingLifespan)
              .map((item) => ({
                ...item,
                depreciation_value:
                  (item.acquisition_value - item.residual_value) /
                  item.lifespan_months,
              }));
            this.response = resp;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },
    calcCosts() {
      const formattedUsersWithCosts = this.response.usersWithCosts.map(
        (user) => ({
          username: user.username,
          member_id: user.member_id,
          hour_per_month: user.hour_per_month,
          unproductively: user.unproductively,
          effectiveMemberHour: user.effectiveMemberHour,
          value: this.monetaryToNumber(user.value),
        })
      );

      const formattedDepreciation = this.goodsFiltered.map((asset) => ({
        workspace_id: asset.workspace_id,
        good_id: asset.id,
        depreciation_amount: this.monetaryToNumber(asset.depreciation_value),
        month: this.selectedMonth,
        year: this.selectedYear,
      }));

      // Primeiro, salva a depreciação e depois salva o histórico
      this.$store
        .dispatch('saveGoodsDepreciation', { formattedDepreciation })
        .then(() => {
          return this.$store.dispatch('saveHistory', {
            workspace_id: this.currentWorkspace.id,
            month: this.selectedMonth,
            year: this.selectedYear,
            formattedUsersWithCosts,
            month_expenses: this.monthExpenses ? this.monthExpenses : 0,
          });
        })
        .then((resp) => {
          console.log(resp);
          this.$router.push({ name: 'cost-per-tasks' });
        })
        .catch((error) => {
          console.error('Erro ao salvar custos:', error);
        });
    },
  },
};
</script>
